body {
    margin: 0;
}
@media screen and (max-width: 1000px){
    .hideMobile {
        display: none;
    }
    .fullwidthmobile {
        width: 100% !important;
    }
}

:where(.css-dev-only-do-not-override-1gpikk6).ant-pagination .ant-pagination-item {
  border-radius: 0; /* Set border radius to 0 */
}
.mobilemenu {
    display: none;
}
@media screen and (max-width: 1000px){
    .sider {
        display: none;
    }
    .mobilemenu {
        display: block;
    }
}
@media screen and (max-width: 1300px){
    .selectResponsive {
        width: 100%;
    }
    .mobileColLG {
        flex-direction: column;
    }
}
@media screen and (max-width: 700px){
    .mobileColSM {
        flex-direction: column;
    }
    .mobileColSM .ant-space-item {
        width: 100%;
    } 
    .mobileColSM .ant-space-item .ant-select {
        width: 100% !important;
    }
}

.ant-table-content {
    overflow-x: auto;
}
.ant-breadcrumb-link.navigate {
    cursor: pointer;
}
.ant-breadcrumb-link.navigate:hover {
    text-decoration: underline;
}

.ant-input-group-addon {
    background-color: #bd8136 !important;
    color: white !important;
}

.fullwidth > .ant-space-item {
    width: 100%
}

.permissions > .ant-space-item:first-child {
    width: auto;
    min-width: 400px;
}

.nodeFullWidth > [title] {
    width: 100%
}
.blocklink {
    cursor: pointer;
    background-color: white;
}
.blocklink:hover {
    background-color: #bd803648;
}